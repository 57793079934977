/* LandingPage.css */

/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Hero Section */
.hero-section {
  background-color: #1c1c1c;
  color: white;
  text-align: center;
  padding: 100px 20px;
}

.hero-content h1 {
  color: white;
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-button {
  padding: 15px 30px;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #e68900;
}

/* Why Choose Us Section */
.why-choose-us {
  background-color: #f4f4f4;
  padding: 50px 20px;
  text-align: center;
}

.why-choose-us h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 280px;
  text-align: center;
}

.card h3 {
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.card p {
  font-size: 1rem;
  color: #666;
}

/* Footer Section */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
    margin-bottom: 20px;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}