/* General styles for the container */
.social-bookmarking {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading styles */
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #444;
    margin-top: 20px;
  }
  
  /* Paragraph styles */
  p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Table styles */
  .bookmark-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .bookmark-table th, .bookmark-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .bookmark-table th {
    background-color: #4CAF50;
    color: white;
  }
  
  .bookmark-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .bookmark-table a {
    color: #1e90ff;
    text-decoration: none;
  }
  
  .bookmark-table a:hover {
    text-decoration: underline;
  }
  
  /* List styling */
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  /* Adding padding and margin */
  ul, p {
    margin-bottom: 15px;
  }