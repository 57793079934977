/* Blog.css */

.blog-container {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .blog-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  .section h2 {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 20px;
  }
  
  .section h3 {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .section p {
    font-size: 1rem;
    color: #555;
  }
  
  .section ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .section ul li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .blog-container {
      padding: 15px;
    }
  
    .blog-header h1 {
      font-size: 2rem;
    }
  
    .section h2 {
      font-size: 1.8rem;
    }
  
    .section h3 {
      font-size: 1.4rem;
    }
  }