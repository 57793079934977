* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  /* Navbar container */
  .navbar {
    background-color: #333;
    padding: 10px 20px;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo a {
    color: white;
    font-size: 24px;
    text-decoration: none;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
  }
  
  .nav-links li {
    margin-left: 20px;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block;
  }
  
  .nav-links a:hover {
    background-color: #575757;
    border-radius: 4px;
  }
  
  /* Hamburger menu */
  .hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .bar {
    width: 30px;
    height: 4px;
    background-color: white;
    border-radius: 5px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .nav-links {
      position: absolute;
      top: 60px;
      right: 0;
      background-color: #333;
      width: 100%;
      height: 100vh;
      flex-direction: column;
      align-items: center;
      display: none;
    }
  
    .nav-links.active {
      display: flex;
    }
  
    .nav-links li {
      margin: 20px 0;
    }
  
    .hamburger {
      display: flex;
    }
  }
  